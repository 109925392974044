import * as React from "react";
import { useContext, useEffect, useRef } from "react";
import { ApolloProvider as ApolloProviderBase } from "@apollo/react-hooks";
import { ApolloClient } from "apollo-client";
import { ApolloLink, concat } from "apollo-link";
import { setContext } from "apollo-link-context";
import { WebSocketLink } from "apollo-link-ws";
import { HttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { getMainDefinition } from "apollo-utilities";
import { ProfileContext } from "../context";
import { cacheStudioDefaultsInit } from "../components/studio/resolvers";
import Auth from "@aws-amplify/auth";
import { useGA } from "../hooks/analytics/useGA";
import { useGATimer } from "../hooks/analytics/useGATimer";
import router from "next/router";
import { REDIRECT_ON_LOGIN_STORAGE_KEY } from "../components/Page";

let token: string;
const getToken = async (): Promise<void> => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    if (user) {
      //Here we check if the user has a JWT token. If its expired, this also refreshes the token.
      token = (await Auth.currentSession()).getIdToken().getJwtToken();
    }
  } catch (exception) {
    if (exception !== "not authenticated") {
      console.error("Failed to fetch authenticated user", exception);
    } else {
      const url = { pathname: router.pathname, query: router.query };
      sessionStorage.setItem(
        REDIRECT_ON_LOGIN_STORAGE_KEY,
        JSON.stringify(url)
      );

      router.push({
        pathname: "/login",
        query: { errMessage: "SESSION_EXPIRED" }
      });
    }
  }
};

const withToken = setContext(() => {
  return getToken();
});

export const ApolloProvider: React.FunctionComponent<any> = ({ children }) => {
  // Init Apollo only if the user is logged in
  const { isAuthenticated } = useContext(ProfileContext);
  useGA();
  const { startTimer, endTimer } = useGATimer();
  const clientRef = useRef<ApolloClient<any>>();
  useEffect(() => {
    if (isAuthenticated) {
      const httpLink = new HttpLink({
        uri: process.env.BINDER_URI
      });

      const authMiddleware = new ApolloLink((operation, forward) => {
        // Start recording before the request starts
        startTimer();
        if (forward !== undefined) {
          operation.setContext({
            headers: {
              authorization: `Bearer ${token}`
            }
          });
          // End the recording once we get the result
          // https://github.com/apollographql/apollo-client/issues/4017#issuecomment-615151013
          // https://www.apollographql.com/docs/link/overview/#context
          return forward(operation).map(result => {
            endTimer({
              category: "ajax",
              label: `binder.${operation.operationName}`,
              variable: "load"
            });
            return result;
          });
        }
        return null;
      });

      const authHttpLink = concat(authMiddleware, httpLink);

      // https://www.apollographql.com/docs/react/advanced/subscriptions/#client-setup
      // Create a WebSocket link:
      const wsLink = new WebSocketLink({
        uri: (process.env.BINDER_URI || "").replace(/http/, "ws"),
        options: {
          reconnect: true
        }
      });

      // using the ability to split links, you can send data to each link
      // depending on what kind of operation is being sent
      const link = withToken.split(
        // split based on operation type
        ({ query }) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === "OperationDefinition" &&
            definition.operation === "subscription"
          );
        },
        wsLink,
        authHttpLink
      );

      clientRef.current = new ApolloClient({
        link: link,
        cache: new InMemoryCache(),
        // From Apollo Local state management docs:
        // If you want to use Apollo Client's @client support to query the cache without using local resolvers,
        // you must pass an empty object into the ApolloClient constructor resolvers option.
        resolvers: {}
      });
      cacheStudioDefaultsInit(clientRef.current);
    }
  }, [isAuthenticated]);
  return clientRef.current ? (
    <ApolloProviderBase client={clientRef.current}>
      {children}
    </ApolloProviderBase>
  ) : (
    children
  );
};
