import { InMemoryCache } from "apollo-boost";
import ApolloClient from "apollo-client";

export const cacheStudioDefaultsInit = (
  client: ApolloClient<InMemoryCache>
) => {
  client.cache.writeData({
    data: {
      audioLanguage: "source",
      sourceVolume: 1,
      sidebarSize: "mw3",
      isPlaying: false,
      syncState: "Saved",
      mediaDuration: -1
    }
  });
};
