/* eslint-disable @typescript-eslint/camelcase */
import * as React from "react";
import { useEffect, useState } from "react";
import Amplify, { Hub } from "@aws-amplify/core";
import Auth from "@aws-amplify/auth";
import { CognitoUser } from "amazon-cognito-identity-js";
import { HubCapsule } from "@aws-amplify/core/lib/Hub";
import { pick, camelCase, mapKeys } from "lodash";
import { ProfileContext } from "../context";
import { Profile } from "../@types/data";
import { GA } from "../analytics/ga";

const AUTH_REQUESTED_KEY = "AUTH_REQUESTED";

const FIELDS_TO_EXTRACT = [
  "name",
  "family_name",
  "given_name",
  "picture",
  "email",
  "sub"
];

const extractGroups = (groupsArr = []) =>
  groupsArr.reduce((acc: any, group: string) => {
    acc[group.toLowerCase()] = true;
    return acc;
  }, {});

const parseProfile = (user: CognitoUser): Profile => {
  // AWS TypeScript definition is not complete or wrong
  const profile = mapKeys(
    // @ts-ignore
    pick(user.attributes, FIELDS_TO_EXTRACT),
    (_: string, k: string) => camelCase(k)
  ) as Profile;

  profile["groups"] = extractGroups(
    // @ts-ignore
    user.signInUserSession.idToken.payload["cognito:groups"]
  );
  profile["canEdit"] =
    profile.groups.superuser || profile.groups.channelmanager;
  return profile;
};

const useEffectHubListener = (
  authChecked: boolean,
  setProfile: React.Dispatch<React.SetStateAction<Profile | null>>,
  setAuthChecked: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const [configured, setConfigured] = useState(false);

  // https://github.com/facebook/react/issues/14326#issuecomment-441680293
  const handleAuth = async (): Promise<void> => {
    if (!authChecked) {
      try {
        const user = await Auth.currentAuthenticatedUser();
        if (user) {
          setProfile(parseProfile(user));
        }
      } catch (exception) {
        if (exception !== "not authenticated") {
          console.error("Failed to fetch authenticated user", exception);
        }
      } finally {
        const authRequested: string | null = sessionStorage.getItem(
          AUTH_REQUESTED_KEY
        );
        // This is to prevent login pop up appearing and disappearing whist the OAuth is happening
        if (!authRequested) {
          setAuthChecked(true);
        } else {
          sessionStorage.removeItem(AUTH_REQUESTED_KEY);
        }
      }
    }
  };

  useEffect(() => {
    let handleHubEvents: (capsule: HubCapsule) => Promise<void>;
    if (typeof window !== "undefined") {
      if (!configured) {
        setConfigured(true);
        Amplify.configure({
          Auth: {
            region: process.env.AWS_REGION,
            identityPoolRegion: process.env.AWS_REGION,
            identityPoolId: process.env.COGNITO_IDENTITY_POOL_ID,
            userPoolId: process.env.COGNITOI_USER_POOL_ID,
            userPoolWebClientId: process.env.COGNITO_CLIENT_ID,
            authenticationFlowType: "USER_PASSWORD_AUTH"
          },
          oauth: {
            domain: process.env.COGNITO_DOMAIN,
            scope: ["email"],
            redirectSignIn: `${window.location.origin}/login`,
            redirectSignOut: `${window.location.origin}/login`,
            responseType: "code"
          }
        });
      }
      handleAuth();

      handleHubEvents = async capsule => {
        switch (capsule.payload.event) {
          case "signIn":
            handleAuth();
            break;
          case "signUp":
            // "signUp" event only happens when the user signs up using email and password, not social.
            setAuthChecked(true);
            break;
          case "signOut":
            setProfile(null);
            break;
          default:
            break;
        }
      };

      Hub.listen("auth", handleHubEvents);
    }

    return () => {
      if (handleHubEvents) {
        Hub.remove("auth", handleHubEvents);
      }
    };
  }, [typeof window === "undefined"]);
};

const ProfileProvider: React.ComponentType = ({ children }) => {
  const [profile, setProfile] = useState<Profile | null>(null);
  // This is used to prevent actions from triggering prior auth check has happened (i.e. redirecting out to the home)
  const [authChecked, setAuthChecked] = useState(false);
  useEffectHubListener(authChecked, setProfile, setAuthChecked);

  useEffect(() => {
    if (profile && profile.sub) {
      GA.set({ userId: profile.sub });
      GA.setDimensions({ userSub: profile.sub }); // Because big G doesn't expose this
    }
  }, [profile]);

  return (
    <ProfileContext.Provider
      value={{
        profile,
        authChecked,
        isAuthenticated: profile && profile.sub,
        loginWithSocial: (...args: any[]) => {
          sessionStorage.setItem(AUTH_REQUESTED_KEY, "true");
          Auth.federatedSignIn(...args);
        },
        loginWithEmail: Auth.signIn.bind(Auth),
        signUpWithEmail: Auth.signUp.bind(Auth),
        logout: (...args: any[]) => {
          setAuthChecked(false); // stops additional redirects after logging out
          return Auth.signOut(...args);
        }
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

export { ProfileProvider };
