import "isomorphic-fetch";
import * as React from "react";
import * as Sentry from "@sentry/browser";
import App from "next/app";
import { SENTRY_TRACKING_ID } from "../src/constants/env";

import "react-dates/lib/css/_datepicker.css";
import "@uppy/core/dist/style.css";
import "@uppy/file-input/dist/style.css";
import "@uppy/progress-bar/dist/style.css";

import "../src/themes/tachyons.css";
import "../src/themes/fonts.css";
import "../src/themes/overrides.css";
import "../src/themes/browserOverrides.css";
import "../src/themes/effects.css";
import "../src/themes/customClasses.css";
import "../src/themes/toggle.css";
import "../src/themes/light.css";

import { ThemeProvider } from "../src/providers/ThemeProvider";
import { SessionProvider } from "../src/providers/SessionProvider";
import { ApolloProvider } from "../src/providers/ApolloProvider";
import { ProfileProvider } from "../src/providers/ProfileProvider";

Sentry.init({
  dsn: SENTRY_TRACKING_ID
});

export default class extends App {
  public componentDidCatch(error: Error, errorInfo: any) {
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });

      Sentry.captureException(error);
    });

    super.componentDidCatch(error, errorInfo);
  }

  public render() {
    const { props } = this as any;
    const { Component, pageProps } = props;

    return (
      <ThemeProvider>
        <ProfileProvider>
          <ApolloProvider>
            <SessionProvider>
              <Component {...pageProps} />
              <style jsx global>{`
                #__next {
                  display: flex;
                  flex-direction: column;
                }
              `}</style>
            </SessionProvider>
          </ApolloProvider>
        </ProfileProvider>
      </ThemeProvider>
    );
  }
}
